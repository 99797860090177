export class DataHelper
{
    hostAddress: string;
    authKey: string;  
    user_id: string;
    miscToken: string;
    unauthorizedCallback: (e: string)=>void ;
    setBusy: (e: boolean)=>void ;
    constructor (_hostAddress: string, _authKey: string = "" , _user_id: string = "", _unauthorizedCallback: (e: string)=>void = (e)=>{},  _setBusy: (e: boolean)=>void = (e)=>{})
    {
        this.hostAddress= _hostAddress;
        this.authKey = _authKey;
        this.user_id = _user_id;
        this.unauthorizedCallback = _unauthorizedCallback;
        this.setBusy = _setBusy;
        this.miscToken = "";
    }

    getPwd = (forencrypt: boolean) =>
    {
      let _pwd = "";
      while (!_pwd)
      {
        _pwd = prompt(forencrypt ? "Please enter your password (This will be required to view the data):" : "Please enter your password used when uploading data:") as string;
      }
      return _pwd;
    }

   objectToQueryString = (obj: any) => {
      var str = [];
      for (var p in obj)
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      return str.join("&");
    }

    downloadFile = (controllerName: string, methodName: string, queryparms: any): void => {
      // Create a link and set the URL using `createObjectURL`
      const link = document.createElement('a');
      link.style.display = 'none';
      link.href = this.hostAddress + controllerName + '/' + methodName + "?" + this.objectToQueryString(queryparms);
      //link.download = "";
  
      // It needs to be added to the DOM so it can be clicked
      document.body.appendChild(link);
      link.click();
  
      // To make this work on Firefox we need to wait
      // a little while before removing it.
      setTimeout(() => {
          URL.revokeObjectURL(link.href);
          link.parentNode?.removeChild(link);
      }, 0);
  }

    GetVersion = (callback: Function = ()=>{}, callbackError: Function=()=>{}) =>
    {
      this.CallSettingsService("GetVersion", {}, {}, callback, callbackError);
    }

    CallMetadataService  = (MethodName: string, DataIn: any, queryParms: any, callback: Function = ()=>{}, callbackError: Function=()=>{}, callType: string = "GET", showLoading = true) =>
    {
      this.CallService("StudyMetadata", MethodName, DataIn, queryParms, callback, callbackError, callType, showLoading );

    }

    CallStudyService  = (MethodName: string, DataIn: any, queryParms: any, callback: Function = ()=>{}, callbackError: Function=()=>{}, callType: string = "GET", showLoading = true) =>
      {
          this.CallService("StudyUpload", MethodName, DataIn, queryParms, callback, callbackError, callType, showLoading );
      }

      CallSettingsService  = (MethodName: string, DataIn: any, queryParms: any, callback: Function = ()=>{}, callbackError: Function=()=>{}, callType: string = "GET", showLoading = true) =>
      {
          this.CallService("Settings", MethodName, DataIn, queryParms, callback, callbackError, callType, showLoading );
      }

      CallDashboardService  = (MethodName: string, DataIn: any, queryParms: any, callback: Function = ()=>{}, callbackError: Function=()=>{}, callType: string = "GET", showLoading = true) =>
      {
          this.CallService("Dashboard", MethodName, DataIn, queryParms, callback, callbackError, callType, showLoading );
      }

      CallService  = (ControllerName: string, MethodName: string, DataIn: any, queryParms: any, callback: Function = ()=>{}, callbackError: Function=()=>{},callType: string = "GET", showLoading: boolean= true) =>
      {
        if (showLoading)
          this.setBusy(true);
          // create a new XMLHttpRequest
    var xhr = new XMLHttpRequest()
    
    //If there is no connected DB give a unique errorCallback
    //if(xhr.)
    

    // get a callback when the server responds
    xhr.addEventListener('load', () => {
      // update the state of the component with the result here
      if (showLoading)
      this.setBusy(false);
      if (xhr.status === 401)
      {
        //console.log("Unauthorized user");
        //Show an authorized screen.
        this.unauthorizedCallback("Unauthorized user");

      }else if(xhr.status>199 && xhr.status<300){
        if (callback)
        {
          callback(xhr.responseText);
        }
      }
      else
      {
        var errorMessage:string= ""

        try{
          var errorReturned=JSON.parse(xhr.responseText).errors;
        //['studyId']
        
          for(let error in errorReturned){
            errorMessage=errorMessage+errorReturned[error];
        }
        }catch{ errorMessage=xhr.responseText}
        
        callbackError(xhr.status,errorMessage);
      }
    });
    xhr.addEventListener('error', (ex) => {
      if (showLoading)
      this.setBusy(false);
      if(callbackError){callbackError(xhr.status, xhr.responseText)}
        // update the state of the component with the result here
        console.log("Error: " + xhr.responseText);
        // if (callback)
        // {
        //   callback(xhr.responseText);
        // }
      })
      let adddQueryParm = "?";
      if (callType === "POST")
      {
        adddQueryParm="";
      }
      
    // open the request with the verb and the url
    xhr.open(callType, this.hostAddress + ControllerName + '/' + MethodName + adddQueryParm + this.objectToQueryString(queryParms));
    if (callType === "POST")
      {
        xhr.setRequestHeader("Accept", "application/json");
        xhr.setRequestHeader("Content-type", "application/json");
      }
      xhr.setRequestHeader("user_id", this.user_id);
      xhr.setRequestHeader("auth_token", this.authKey);
      
    // send the request
    xhr.send(JSON.stringify(DataIn));

  
      }

      CallDataServiceFiles  = (ControllerName: string, MethodName: string, DataIn: FormData | Blob, callback: Function, callbackError: Function = ()=>{}, progressEvent: (arg0: ProgressEvent)=>void, showLoading: boolean = true) =>
      {
        if (showLoading)
        this.setBusy(true);
          // create a new XMLHttpRequest
    var xhr = new XMLHttpRequest()
  
    // get a callback when the server responds
    xhr.addEventListener('load', () => {
      if (showLoading)
      this.setBusy(false);
      // update the state of the component with the result here
      //console.log("Event Called: " + xhr.responseText);
      if(xhr.status>199 && xhr.status<300){
        if (callback)
        {
          callback(xhr.responseText);
        }
      }
      else
      {
        var errorMessage:string= ""

        try{
          var errorReturned=JSON.parse(xhr.responseText).errors;
        //['studyId']
        
          for(let error in errorReturned){
            errorMessage=errorMessage+errorReturned[error];
        }
        }catch{ errorMessage=xhr.responseText}
        
        
        
        callbackError(xhr.status,errorMessage);
      }
    });
    xhr.addEventListener('error', (e) => {
      if (showLoading)
      this.setBusy(false);
      if(callbackError){callbackError(xhr.status, xhr.responseText, xhr.statusText)}
      // update the state of the component with the result here
      console.log("Error: " + xhr.status + " - " + xhr.responseText);
     
    });
    xhr.upload.onprogress = function(progress) {
      if (progressEvent)
        progressEvent(progress);
  
    };
    //Doesn't seem to be activated here
    // xhr.addEventListener("progress", (progress) => {
      
    //   console.log("File upload progress:");
    //   console.log(progress);
    // });
 
    // open the request with the verb and the url
    xhr.open('POST', this.hostAddress  + ControllerName + '/' + MethodName,);
    xhr.setRequestHeader("user_id", this.user_id);
    xhr.setRequestHeader("auth_token", this.authKey);
  
    // xhr.setRequestHeader("content-type", "multipart/form-data");
    // send the request
    xhr.send(DataIn);
      }


      GetUserDetails  = (callback: (userDetails: any) => void) =>
      {

          // create a new XMLHttpRequest
    var xhr = new XMLHttpRequest()
  
    // get a callback when the server responds
    xhr.addEventListener('load', () => {
      // update the state of the component with the result here
      
      if (callback)
      {
        if (xhr.status >= 100 && xhr.status < 399)
        {
          try
          {
          
            let userDetails = JSON.parse(xhr.responseText);
            callback(userDetails[0]);
         
          } catch (ex)
          {
            //Otherwise there is no auth so don't do anything
            callback({
              autherror: "Authentication failed."
            });
          }
        }else
        {
            //Otherwise there is no auth so don't do anything
            callback({
              autherror: "Authentication failed"
            });
        }


      }
    });
    xhr.addEventListener('error', (ex) => {
        // update the state of the component with the result here
        // callback( {
        //   user_id: "Unknown user"
        // });

        console.log(ex);
          //Otherwise there is no auth so don't do anything
          callback({
            autherror: "Authentication failed"
          });
        // if (callback)
        // {
        //   callback(xhr.responseText);
        // }
      });
    // open the request with the verb and the url
    xhr.open("GET", "/.auth/me");
    // send the request
    xhr.send();
    
 
      }


    attemptSaveUsage =() =>{
       //http://ip-api.com/json/
  try{
  
    // create a new XMLHttpRequest
    var xhrloc = new XMLHttpRequest()
   
    // get a callback when the server responds
    xhrloc.addEventListener('load', () => {
      // update the state of the component with the result here
      
     
       let locDetails: any = {};
        if (xhrloc.status >= 100 && xhrloc.status < 399)
        {
         try{ 
           locDetails = JSON.parse(xhrloc.responseText);
           this.CallSettingsService("SaveLocDetail", {"locationName": locDetails?.country_name}, {}, ()=>{}, ()=>{}, "POST", false);
            
         } catch (ex)
         {
           
         }
        }
        
 
      
    });
    xhrloc.addEventListener('error', () => {
     
    });
    // open the request with the verb and the url
    xhrloc.open("GET", "https://ipapi.co/json/");
    // send the request
    xhrloc.send();
 
   
   }catch(ex2)
   {
  
   }
    }
}