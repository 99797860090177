import React, {RefObject} from "react";
import { StudyModel } from "../Models/StudyModel";
import { InputItem } from "../Components/InputItem";
import { DataHelper } from "../Helpers/DataHelper";
import { ErrorWindow } from "../Components/ErrorWindow";
import { InfoBox } from "../Components/InfoBox";
import { PopupWindow } from "../Components/PopupWindow";
import { PiTrash } from "react-icons/pi";

export class DashboardControlWindow extends React.Component<
    {   allStudies: StudyModel[], 
        studySelectedDefault: StudyModel, 
        onstudySelected: (studyValue: StudyModel, studyIndex: number) => void  
        dataClient: DataHelper
        //Properties go here
    },
    {
        dataToDelete: string,
        studySelected: StudyModel,
        popupVisible: boolean,
        contentVisible: boolean,

        //State goes here
    }
> {

    ;

    errorWindow:  RefObject<ErrorWindow>;
    infoBox: RefObject<InfoBox>;
    
    constructor(props: any) {
        super(props);
        this.errorWindow = React.createRef();
        this.infoBox = React.createRef();
        this.state = {

            dataToDelete: "",
            studySelected: this.props.studySelectedDefault,
            popupVisible: false,
            contentVisible: this.props.studySelectedDefault ? true : false
            //Initialize ALL state variables here
        }
    }

    componentDidMount(): void {

    }

    //This is where our external functions and event handlers go.

    onDropdownChanged = (name:string,value:string)  => {
        if (value !== "--Select--"){
            let selected = this.props.allStudies.find(study=>study.name===value);
            this.setState({contentVisible: true, studySelected: selected as StudyModel})
        }
        else {(this.setState({contentVisible: false}))}
        
        
     }

    handleDelete = (dataToDelete:string) => {

            if (this.state.studySelected.name) {
                this.props.dataClient.CallSettingsService("DeleteStudyContent", {}, {toDelete: dataToDelete, studyId: this.state.studySelected.id }, () => {
                    (this.infoBox.current as InfoBox).show("Succesfully deleted")
                }, (status: number, errorMessage: string) => {
                    if (this.errorWindow && this.errorWindow.current as ErrorWindow)
                        (this.errorWindow.current as ErrorWindow).show(status, errorMessage);
                }, "DELETE");
            } 
            //Deletion of all data for all studies is not supported
            // else
            // {
            //     if (dataToDelete === "allstudies")
            //     {
            //         this.props.dataClient.CallSettingsService("DeleteStudyContent", {}, {toDelete: dataToDelete, studyId: "" }, () => {
            //             (this.infoBox.current as InfoBox).show("Succesfully deleted")
            //         }, (status: number, errorMessage: string) => {
            //             if (this.errorWindow && this.errorWindow.current as ErrorWindow)
            //                 (this.errorWindow.current as ErrorWindow).show(status, errorMessage);
            //         }, "DELETE");
            //     }
            // }
        
    }

    render() {
        //THis is where we return our HTML for the UI

        let options = this.props.allStudies.map((study)=>study.name);
        options.splice(0,0,"--Select--");

        return (
            <>
                <InfoBox ref={this.infoBox} title="Confirmation" type="confirmation"></InfoBox>
                <ErrorWindow ref={this.errorWindow}></ErrorWindow>

                    <div>
                    {((this.state.popupVisible) ?
                            <PopupWindow title={this.state.dataToDelete === "repair" ? "Are you sure you want to repair the data in this study?" : "Are you sure you want to delete data for all " + this.state.dataToDelete +" in this study?"} closeClicked={() => { this.setState({ popupVisible: false }) }}>
                                <button className="primaryButton" onClick={() => { this.handleDelete(this.state.dataToDelete); this.setState({ popupVisible: false }); }}>Yes</button>
                                <button className="primaryButton" onClick={() => {this.setState({ popupVisible: false })}}>No</button>
                            </PopupWindow> : <></>)}
                            {/* <div className="dashboardItem">Delete all studies
                        <div className="dashboardItem"><PiTrash className="buttonIcon" onClick={() =>this.setState({ popupVisible: true, dataToDelete: "allstudies"})} /></div>                       
                        </div> */}
                        <div className="dashboardItem">Select Study
                        <InputItem type="dropdown" title={""} name="studiesDropdown" onChange={this.onDropdownChanged} value= {this.props.studySelectedDefault.name? this.props.studySelectedDefault.name: options.at(0)} options={options}></InputItem>                   
                        </div>
                        
                        {((this.state.contentVisible) ?
                        
                       
                        
                        <div>

                        <div className="dashboardItem">
                        <button className="primaryButton" onClick={() =>this.setState({ popupVisible: true, dataToDelete: "repair"})}>Repair measurement data</button>
                        </div>


                        <div className="dashboardItem">Delete all procedures
                        <div className="dashboardItem"><PiTrash className="buttonIcon" onClick={() =>this.setState({ popupVisible: true, dataToDelete: "procedures"})} /></div>
                        </div>

                        <div className="dashboardItem">Delete all measurements
                        <div className="dashboardItem"><PiTrash className="buttonIcon" onClick={() =>this.setState({ popupVisible: true, dataToDelete: "measurements"})} /></div>
                        </div>

                        <div className="dashboardItem">Delete all thumbnails
                        <div className="dashboardItem"><PiTrash className="buttonIcon" onClick={() =>this.setState({ popupVisible: true, dataToDelete: "thumbnails"})} /></div>
                        </div>

                        <div className="dashboardItem">Delete study
                        <div className="dashboardItem"><PiTrash className="buttonIcon" onClick={() =>this.setState({ popupVisible: true, dataToDelete: "study"})} /></div>                       
                        </div>
                        </div>
                        :<></>)}
                    </div>

            </>
        );
    }
}