import React from "react";


export class ImageWithSVGPoints extends React.Component<
    {
        imageSrc: string;
        imageWidth: number;
        imageHeight: number;
        points: number[][];
        onPointClick?: (point: number[], index: number) => void;
        scalePoints: boolean;//want us to scale the point so your image?
        graphCenterPixls:number[];//then i need the center in the pixels
        graphSizePixls:number[];//the size of the image that you want it scaled to in pixels
        graphAxisLengthUnits:number[];//and the length of you axis, in the units they are in
    },
    {
        
    }
> {
    constructor(props: any) {
        super(props);
        this.state = {
            
        }
    }

    static defaultProps = {
        imageWidth: 300,
        imageHeight: 300,
        points: [],
        graphSizePixls: [300,300],
        grapCenterPixls: [150,150],
        graphAxisLengthUnits:[10,10],
        scalePoints: true
    }


    handlePointClick = (point: number[], index: number): void => {
        if (this.props.onPointClick) {
            this.props.onPointClick(point, index);
        }
    }

    render() {
        const { imageSrc, imageWidth, imageHeight} = this.props;
        const {graphCenterPixls, graphSizePixls, graphAxisLengthUnits, scalePoints} = this.props;
        let scaledPoints = this.props.points;
        if(scalePoints){
            scaledPoints = this.props.points.map(point => (
                [1*point[0] * (graphSizePixls[0]/graphAxisLengthUnits[0]) + graphCenterPixls[0], -1*point[1]*(graphSizePixls[1]/graphAxisLengthUnits[1]) + graphCenterPixls[1]]
              )) ?? [];
        }

        return (
            <div style={{ position: 'relative', display: 'inline-block' }}>
                <img src={imageSrc} alt="" width={imageWidth} height={imageHeight} />
                <svg
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                    }}
                    viewBox={`0 0 ${imageWidth} ${imageHeight}`}
                >
                    {scaledPoints.map((point, index) => (
                        <circle
                            key={index}
                            cx={point[0]}
                            cy={point[1]}
                            r="3.5"
                            fill="red"
                            stroke="white"
                            strokeWidth="1"
                            onClick={() => this.handlePointClick(point, index)}
                            style={{ cursor: 'pointer' }}
                        />
                    ))}
                </svg>
            </div>
        );
    }
}